<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-data-table
      :headers="headers"
      :items="listProducts"
      :loading="isLoadingProducts"
      :server-items-length="productsMeta.totalItems"
      :options.sync="options"
      :items-per-page="30"
      :footer-props="{ 'items-per-page-options': [5, 30, 50] }"
    >
      <template v-slot:item.image="{ item }">
        <v-img
          v-if="item.image"
          :src="item.image.url"
          max-height="50"
          max-width="50"
        />
        <v-img
          v-else
          src="@/assets/product.png"
          max-height="50"
          max-width="50"
        />
      </template>
      <template v-slot:item.express_status="{ item }">
        <v-chip
          :color="getColor(item.express_status)"
          dark
        >
          {{ item.express_status ? $t("active") : $t("inactive") }}
        </v-chip>
      </template>
      <template v-slot:item.reserved_quantities="{ item }">
        <v-btn
          text
          plain
          large
          @click="showOrders(item)"
          v-if="item.reserved_quantities > 0"
        >
          {{ item.reserved_quantities }}
        </v-btn>
      </template>
      <template v-slot:item.qte_in_stock_management="{ item }">
        <span :class="getQteColor(item)">{{
          item.qte_in_stock_management
        }}</span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-row>
          <v-icon
            v-if="$admin.can('stockManagement-view')"
            small
            class="mr-2"
            @click="showProductDetails(item)"
          >
            mdi mdi-lan
          </v-icon>
          <v-icon
            small
            class="mr-2"
            @click="showBatchesDetails(item)"
          >
            mdi mdi-clipboard-text-outline
          </v-icon>
        </v-row>
      </template>
      <template v-slot:no-data>
        {{ $t("no_data_available") }}
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>{{ $t("filter_options") }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            v-if="$admin.can('product-export')"
            class="mr-4 v-btn v-btn--outlined theme--light v-size--default primary--text"
            @click="getCSVResult(options)"
            :loading="is_loading_csv_detail"
            :disabled="is_loading_csv_detail"
          >
            {{ $t("stockManagements.export_details") }}
          </v-btn>
          <v-btn
            v-if="$admin.can('product-export')"
            color="primary"
            @click="getCSV(options)"
            :loading="is_loading_csv"
            :disabled="is_loading_csv"
          >
            {{ $t("stockManagements.export_result") }}
          </v-btn>
        </v-toolbar>
        <v-row>
          <v-col>
            <v-text-field
              clearable
              v-model="options.search"
              :label="$t('search')"
              class=""
            ></v-text-field>
          </v-col>
          <v-col>
            <v-autocomplete
              clearable
              v-model="options.hub_ids"
              :items="hubs"
              item-text="name"
              item-value="id"
              :label="$t('hubs')"
              :search-input.sync="search_hub"
              :loading="isLoadingHubs"
              no-filter
              multiple
              chips
            ></v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete
              clearable
              v-model="options.category_parent_id"
              :items="categories"
              item-text="name"
              item-value="id"
              :loading="isLoadingCategories"
              @change="loadCategory(options.category_parent_id, 3)"
              :label="$t('category')"
            ></v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete
              clearable
              v-model="options.category_id"
              :items="sub_categories"
              item-text="name"
              item-value="id"
              :loading="isLoadingSubCategories"
              :label="$t('sub_category')"
            ></v-autocomplete>
          </v-col>
          <v-col>
            <v-menu
              v-model="menu_date"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
              clearable
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="options.date"
                  clearable
                  :label="$t('date')"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="options.date"
                no-title
                color="green lighten-1"
                header-color="green lighten-1"
                @input="menu_date_start = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </template>
    </v-data-table>
    <show-product></show-product>
    <show-orders
      v-if="isOrderModalOpen"
      :isModalOpen="isOrderModalOpen"
      :currentItem="currentItem"
      :toggleModal="() => toggleOrdersModal()"
    ></show-orders>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import debounce from "lodash/debounce";
import stockService from "@/store/services/stock-management-service";
import categoryService from "@/store/services/category-service";

export default {
  components: {},

  computed: {
    ...mapGetters({
      isLoadingCategories: "categories/isLoadingCategories",
      isLoadingBrands: "brands/isLoadingBrands",
      isLoadingHubs: "hubs/isLoadingHubs",
      isLoadingSections: "sections/isLoadingSections",
      isLoadingAttributes: "attributes/isLoadingAttributes",

      hubs: "hubs/listHubs",
      brands: "brands/listBrands",
      stores: "stores/listStores",
      categories: "categories/listCategories",

      isLoadingProducts: "stockManagements/isLoadingStockManagements",
      listProducts: "stockManagements/list",
      productsMeta: "stockManagements/meta",
    }),
  },

  watch: {
    options: {
      handler: debounce(async function () {
        this.is_loading_product = true;
        await this.$store
          .dispatch("stockManagements/list", this.options)
          .then(() => {
            this.is_loading_product = false;
            if (this.options.page > (this.productsMeta?.lastPage || 1)) {
              this.options.page = 1;
            }
          });
      }, 1000),
      deep: true,
    },

    search_hub: debounce(function (search) {
      if (this.isLoadingHubs) return;

      this.$store.dispatch("hubs/list", {
        store_id: this.store_id,
        itemsPerPage: 30,
        search,
      });
    }, 1000),
  },

  data() {
    return {
      sub_categories: [],
      options: { itemsPerPage: 30 },
      search_hub: "",
      is_loading_product: false,
      is_loading_csv: false,
      is_loading_csv_detail: false,
      currentItem: {},
      isOrderModalOpen: false,

      headers: [
        {
          text: this.$t("image"),
          align: "start",
          value: "image",
          sortable: false,
        },
        {
          text: this.$t("name"),
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: this.$t("product_id"),
          align: "start",
          sortable: true,
          value: "generated_code",
        },
        {
          text: this.$t("sku"),
          align: "start",
          sortable: true,
          value: "barcode",
        },

        {
          text: this.$t("quantity"),
          value: "qte_in_hubs",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("qte_in_cw"),
          align: "start",
          sortable: true,
          value: "qte_in_cw",
        },

        {
          text: this.$t("qte_in_ds"),
          align: "start",
          sortable: true,
          value: "qte_in_ds",
        },

        {
          text: this.$t("value"),
          align: "start",
          sortable: true,
          value: "stock_value",
        },
        {
          text: this.$t("actions"),
          value: "actions",
          sortable: false,
        },
      ],
    };
  },
  mounted() {},
  methods: {
    async loadCategory(parent_id, level) {
      if (level == 2) {
        const categories = await categoryService.list({
          parent_id,
          level,
        });
        this.categories = categories;
        this.options.category_id = null;
      } else if (level == 3) {
        const sub_categories = await categoryService.list({
          level: 3,
          parent_id,
        });
        this.sub_categories = sub_categories;
      }
    },

    showProductDetails(item) {
      this.$store.commit("stockManagementsProduct/SET_FILTERS", {
        hub_ids: this.options.hub_ids,
        date_range: this.options.date
          ? [this.options.date, this.options.date]
          : null,
      });

      this.$router.push({
        name: "stock.managements.view",
        params: { id: item.product_id },
      });
    },

    showBatchesDetails(item) {
      this.$store.commit("stockManagementsBatch/SET_FILTERS", {
        hub_ids: this.options.hub_ids,
        date_range: this.options.date
          ? [this.options.date, this.options.date]
          : null,
      });

      this.$router.push({
        name: "stock.managements.view-batches",
        params: { id: item.product_id },
      });
    },

    async showOrders(item) {
      try {
        const data = await stockService.listReservedQuantityOrders({
          product_id: item.id,
          hub_id: item.hub_id ?? 0,
          hub_ids: this.options.hub_ids ?? null,
        });

        this.currentItem = { ...item, orders: data.orders };
        this.toggleOrdersModal();
      } catch (error) {
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
    },

    getColor(status) {
      if (status === "active" || status == 1) return "green";
      else return "red";
    },

    getQteColor(item) {
      if (item.qte_in_hub == item.min_qte_in_hub)
        return "orang--text text--darken-3";
      else if (item.qte_in_hub < item.min_qte_in_hub)
        return "red--text text--darken-3";
      else return "";
    },

    async getCSV(filters) {
      this.is_loading_csv = true;

      await this.$store
        .dispatch("stockManagements/exportResultCSV", filters)
        .then((data) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "stock_managements.csv");
          document.body.appendChild(link);
          // window.open(link);
          link.click();
          this.is_loading_csv = false;
        })
        .catch((error) => {
          this.is_loading_csv = false;
          this.$store.dispatch(
            "alerts/error",
            error.response?.data?.message ?? error.response?.statusText
          );
        });
    },
    async getCSVResult(filters) {
      this.is_loading_csv_detail = true;

      await this.$store
        .dispatch("stockManagements/exportDetailstCSV", filters)
        .then((data) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "stockManagementsDetails.csv");
          document.body.appendChild(link);
          // window.open(link);
          link.click();
          this.is_loading_csv_detail = false;
        })
        .catch((error) => {
          this.is_loading_csv_detail = false;
          this.$store.dispatch(
            "alerts/error",
            error.response?.data?.message ?? error.response?.statusText
          );
        });
    },
  },
};
</script>
